import React from 'react';
import { IonItem, IonLabel, IonToggle, IonBadge, IonItemDivider } from '@ionic/react';
import moment from 'moment';

interface Props {
  presence: Presence
  onPresenceChange: any
  disabled?: boolean
}

interface Presence {
  id: any
  date: Date
  current: boolean
  offices: any[]
}

class OfficePresence extends React.Component<Props> {
  render() {
    return (
      <div>
        <IonItemDivider color={this.props.presence.current ? "primary" : "secondary"}>
          <IonLabel>{moment(this.props.presence.date, 'YYYY-MM-DD').format('dddd DD.MM.YYYY')}</IonLabel>
        </IonItemDivider>
        {this.props.presence.offices ? this.props.presence.offices.map((office: any) =>
          <IonItem key={office.office + '.' + this.props.presence.id}>
            <IonLabel>{office.office}</IonLabel>
            <IonBadge color="light">{office.count}</IonBadge>
            <IonToggle 
              slot="end" 
              checked={office.present}
              disabled={this.props.disabled}
              onIonChange={e => this.props.onPresenceChange(this.props.presence.date, office.office, e.detail.checked)}
            />
          </IonItem>
        ) : []}
      </div>
    );
  }
}

export default OfficePresence;
