import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IonContent, IonPage, IonItem, IonTextarea, IonButton, IonToast } from '@ionic/react';
import axios from 'axios';
import firebase from 'firebase';
import TabHeader from '../components/TabHeader';

const Feedback: React.FC = () => {

  const { handleSubmit, register, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      feedback: ''
    }
  });

  const [showThankyou, setShowThankyou] = useState(false);

  const sendFeedback = (data: any, e: any) => {
    const currentUser = firebase.auth().currentUser;
    if(currentUser) {
      currentUser.getIdToken(true)
        .then(token => {
          axios.post('feedback', data, {
            headers: { Authorization: `Bearer ${token}` }
          });
          reset();
          setShowThankyou(true);
        }).catch(function(error) {
          console.error('Authentication error');
        }
      );    
    } else {
      console.error('Authentication error');
    }
  }

  return (
    <IonPage>
      <TabHeader title="Podziel się opinią"/>
      <IonContent fullscreen>
        <form onSubmit={handleSubmit(sendFeedback)}>
          <IonItem>
            <IonTextarea name="feedback" ref={register({required: true})} />
          </IonItem>
          <IonButton type="submit" expand="full"
            // disabled={formState.isValid === false}
          >Wyślij</IonButton>
        </form>
        <IonToast
          isOpen={showThankyou}
          onDidDismiss={() => setShowThankyou(false)}
          message="Dziękujemy za opinię."
          duration={1000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Feedback;
