import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { carOutline, desktopOutline, helpCircleOutline } from 'ionicons/icons';
import OfficeTab from './pages/OfficeTab';
import ParkingTab from './pages/ParkingTab';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import firebase from 'firebase';
import axios from 'axios';

import Feedback from './pages/Feedback';
import SignIn from './pages/SignIn';

const firebaseConfig = {
  apiKey: "AIzaSyBl8UZDnEN2dqSp8yhHRjydbTWMkeOmE2Q",
  authDomain: "pretius-office.firebaseapp.com",
  databaseURL: "https://pretius-office.firebaseio.com",
  projectId: "pretius-office",
  storageBucket: "pretius-office.appspot.com",
  messagingSenderId: "749764755898",
  appId: "1:749764755898:web:7fef4a24406ae683d49d83",
  measurementId: "G-ZZGZDLSE3V"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = ({
  parkingLockHour: 9,
  parkingPrice: 20,
});

axios.defaults.baseURL = 'api';

class App extends React.Component {
  
  state = {
    isSignedIn: undefined,
  };

  unregisterAuthObserver: any
  
  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp.auth().onAuthStateChanged((user) => {
      if(!!user) {
        analytics.setUserId(user.email ? user.email : '');
      }
      this.setState({
        isSignedIn: !!user && user.email?.endsWith('@pretius.com')
      });
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
  
  render() {
    return (
      <IonApp>
        
      {this.state.isSignedIn !== undefined && !this.state.isSignedIn &&
        <SignIn/>
      }
      {this.state.isSignedIn &&
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route path="/office" component={OfficeTab} exact={true} />
              <Route path="/parking" component={ParkingTab} exact={true} />
              <Route path="/feedback" component={Feedback} exact={true} />
              <Route path="/" render={() => <Redirect to="/office" />} exact={true} />
            </IonRouterOutlet>
            <IonTabBar slot="bottom" onIonTabsDidChange={(e:any) => analytics.logEvent('tab_open', {name: e.detail.tab})}>
              <IonTabButton tab="office" href="/office">
                <IonIcon icon={desktopOutline} />
                <IonLabel>Biuro</IonLabel>
              </IonTabButton>
              <IonTabButton tab="parking" href="/parking">
                <IonIcon icon={carOutline} />
                <IonLabel>Parking</IonLabel>
              </IonTabButton>
              <IonTabButton tab="feedback" href="/feedback">
                <IonIcon icon={helpCircleOutline} />
                <IonLabel>Feedback</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      }
      </IonApp>
    );
  }
}

export default App;
