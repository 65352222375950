import React from 'react';
import { IonToast } from '@ionic/react';

interface ContainerProps {
  message: string
  onDidDismiss: any
}

const ErrorMessage: React.FC<ContainerProps> = ({ message, onDidDismiss }) => {
  
  return (
    <IonToast
      isOpen={!!message}
      message={message}
      duration={4000}
      color="danger" 
      onDidDismiss={onDidDismiss}
    />
  );
};

export default ErrorMessage;
