import React from 'react';
import { IonIcon, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/react';
import { power, } from 'ionicons/icons';
import firebase from 'firebase';

interface ContainerProps {
  title: string;
}

const TabHeader: React.FC<ContainerProps> = ({ title }) => {
  
  const logout = () => {
    firebase.auth().signOut();
  } 

  return (
    <IonHeader>
        <IonToolbar>
          <IonTitle>{title}</IonTitle> 
          <IonButtons slot="end">
            <IonButton style={!!firebase.auth().currentUser ? {} : {display: 'none'}} onClick={logout}>
              <IonIcon slot="icon-only" icon={power} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
  );
};

export default TabHeader;
