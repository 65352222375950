import React from 'react';
import { 
  IonContent, IonPage, IonLabel, IonItem, IonItemGroup, IonRefresher, IonRefresherContent
} from '@ionic/react';
import axios from 'axios';
import firebase from 'firebase';
import TabHeader from '../components/TabHeader';
import OfficePresence from '../components/OfficePresence';
import { chevronDownCircleOutline } from 'ionicons/icons';
import ErrorMessage from '../components/ErrorMessage';

interface Props {
}

interface State {
  presence: any
  errorMessage: string
  loading: boolean
}

class OfficeTab extends React.Component<Props, State> {
  constructor(props:any ) {
    super(props);
    this.state = {
      presence: [],
      errorMessage: '',
      loading: false
    };
  }

  componentDidMount() {
    this.loadPresence();
  }

  loadPresence(refreshEvent?: CustomEvent) {
    const self = this;
    if(refreshEvent) {
      firebase.analytics().logEvent('office_refresh');
    }
    const currentUser = firebase.auth().currentUser;
    if(currentUser) {
      currentUser.getIdToken(true)
        .then(token => {
          axios.get('office/presence', {
            headers: { Authorization: `Bearer ${token}` }
          }).then(response => {
            this.setState((state, props) => {
              return {
                presence: response.data
              };
            });
          }).catch(e => {
            console.error('Błąd pobierania danych.', e);
            self.setState((state, props) => {
              return {
                errorMessage: 'Błąd pobierania danych.' + e
              };
            });
          });
        })
        .then(() => refreshEvent?.detail.complete())
        .catch(function(error) {
          console.error('Błąd uwierzytelniania');
          self.setState((state, props) => {
            return {
              errorMessage: 'Błąd uwierzytelniania.'
            };
          });
          refreshEvent?.detail.complete();
        }
      );    
    } else {
      console.error('Błąd uwierzytelniania');
      self.setState((state, props) => {
        return {
          errorMessage: 'Błąd uwierzytelniania.'
        };
      });
    }
  }

  changePresence(date: Date, office: string, present: boolean) {
    const self = this;    
    firebase.analytics().logEvent(present ? 'office_presence_add' : 'office_presence_remove', {office: office, date: date});
    
    let presence = [...this.state.presence];
    const presenceIdx = presence.findIndex((p) => p.date === date);
    let p = {...presence[presenceIdx]};

    p.offices[p.offices.findIndex((o:any) => o.office === office)].present = present;
    presence[presenceIdx] = p;
    this.setState({
      loading: true,
      presence: presence
    });

    const currentUser = firebase.auth().currentUser;
    if(currentUser) {
      currentUser.getIdToken(true)
        .then(token => {
          axios.post('office/presence', 
          {
            date: date,
            office: office,
            present: present
          }, 
          {
            headers: { Authorization: `Bearer ${token}` }
          }).then(response => {
            this.setState((state, props) => {
              return {
                presence: response.data,
                loading: false
              };
            });
          }).catch(e => {
            console.error('Application error.', e);
            self.setState((state, props) => {
              return {
                errorMessage: 'Błąd zapisu.' + e,
                loading: false
              };
            });
          });
        }).catch(function(error) {
          console.error('Błąd uwierzytelniania');
          self.setState((state, props) => {
            return {
              errorMessage: 'Błąd uwierzytelniania.',
              loading: false
            };
          });
        }
      );    
    } else {
      console.error('Błąd uwierzytelniania');
      self.setState((state, props) => {
        return {
          errorMessage: 'Błąd uwierzytelniania.',
          loading: false
        };
      });
    }
  }

  render() {
    return (
      <IonPage>
        <TabHeader title="Obecność w biurze"/>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={(e) => this.loadPresence(e)}>
            <IonRefresherContent pullingIcon={chevronDownCircleOutline}/>
          </IonRefresher>
          {/* <IonItem>
            <IonLabel>Ilość osób w biurze teraz:</IonLabel>
            <IonBadge color="light" slot="end">32</IonBadge>
          </IonItem> */}
          <IonItem>
            <IonLabel>Kiedy wpadasz?</IonLabel>
          </IonItem>
          <IonItemGroup>
            {this.state.presence.map((item: any) =>
              <OfficePresence 
                key={item.id}
                presence={item} 
                disabled={this.state.loading}
                onPresenceChange={(date: Date, office: string, present: boolean) => this.changePresence(date, office, present)}
              />
            )}
          </IonItemGroup>
          <ErrorMessage 
            message={this.state.errorMessage} 
            onDidDismiss={() => this.setState({errorMessage: ''})}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default OfficeTab;
