import React from 'react';
import { IonContent, IonPage, IonItemGroup, IonRefresher, IonRefresherContent, IonButton, IonItem, IonText } from '@ionic/react';
import axios from 'axios';
import firebase from 'firebase';
import TabHeader from '../components/TabHeader';
import ParkingSpace from '../components/ParkingSpace';
import { chevronDownCircleOutline } from 'ionicons/icons';
import ErrorMessage from '../components/ErrorMessage';

interface Props {
}

interface State {
  spaces: any
  errorMessage: string
  showReservationConfirm: boolean
  parkingLockHour: string
  parkingPrice: string
  loading: boolean
}

class ParkingTab extends React.Component<Props, State> {
  config: firebase.remoteConfig.RemoteConfig

  constructor(props:any ) {
    super(props);
    this.config = firebase.remoteConfig();
    this.state = {
      spaces: [],
      errorMessage: '',
      showReservationConfirm: false,
      parkingLockHour: '',
      parkingPrice: '',
      loading: false,
    };
  }
  
  componentDidMount() {
    this.config.fetchAndActivate()
    .then((result) => {
      this.setState({
        parkingLockHour: this.config.getString('parkingLockHour'),
        parkingPrice: this.config.getString('parkingPrice')
      });
    });
    this.loadSpaces();
  }
  
  loadSpaces(refreshEvent?: CustomEvent) {
    const self = this;
    if(refreshEvent) {
      firebase.analytics().logEvent('parking_refresh');
    }
    const currentUser = firebase.auth().currentUser;
    if(currentUser) {
      currentUser.getIdToken(true)
        .then(token => {
          axios.get('parking', {
            headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
            self.setState((state, props) => {
              return {
                spaces: response.data
              };
            });
          })
          .catch(e => {
            console.error('Błąd pobierania danych.', e);
            self.setState((state, props) => {
              return {
                errorMessage: 'Błąd pobierania danych.' + e
              };
            });
          });
        })
        .then(() => refreshEvent?.detail.complete())
        .catch(function(error) {
          console.error('Błąd uwierzytelniania');
          self.setState((state, props) => {
            return {
              errorMessage: 'Błąd uwierzytelniania.'
            };
          });
          refreshEvent?.detail.complete();
        }
      );    
    } else {
      console.error('Błąd uwierzytelniania');
      self.setState((state, props) => {
        return {
          errorMessage: 'Błąd uwierzytelniania.'
        };
      });
    }
  }

  changeReservation(date:Date, reserved: boolean) {
    const self = this;
    firebase.analytics().logEvent(reserved ? 'parking_reservation_reserve' : 'parking_reservation_release');
    
    let spaces = [...this.state.spaces];
    const spaceIdx = spaces.findIndex((s) => s.date === date);
    let space = {...spaces[spaceIdx]};
    space.reserved = reserved;
    spaces[spaceIdx] = space;
    this.setState({
      loading: true,
      spaces: spaces
    });
    
    const currentUser = firebase.auth().currentUser;
    if(currentUser) {
      currentUser.getIdToken(true)
        .then(token => {
          axios.post('parking/' + (reserved ? 'reserve' : 'release'), 
          {
            id: date
          }, 
          {
            headers: { Authorization: `Bearer ${token}` }
          }).then(response => {
            this.setState((state, props) => {
              return {
                spaces: response.data,
                loading: false
              };
            });
          }).catch(e => {
            console.error('Application error.', e);
            self.setState((state, props) => {
              return {
                errorMessage: 'Błąd zapisu.' + e,
                loading: false
              };
            });
          });
        })
        .catch(function(error) {
          console.error('Błąd uwierzytelniania');
          self.setState((state, props) => {
            return {
              errorMessage: 'Błąd uwierzytelniania.',
              loading: false
            };
          });
        }
      );    
    } else {
      console.error('Błąd uwierzytelniania');
      self.setState((state, props) => {
        return {
          errorMessage: 'Błąd uwierzytelniania.',
          loading: false
        };
      });
    }
  }

  render() {
    return (
      <IonPage>
        <TabHeader title="Rezerwacja parkingu"/>
        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={(e) => this.loadSpaces(e)}>
            <IonRefresherContent pullingIcon={chevronDownCircleOutline}/>
          </IonRefresher>
          <IonItem>
            <IonText>
              Odwoływanie rezerwacji: <b>do {this.state.parkingLockHour}:00</b>
              <div hidden={!this.state.parkingPrice}>
                Koszt miejsca: <b>{this.state.parkingPrice} PLN/dzień</b>
              </div>
            </IonText>
          </IonItem>
          <IonItemGroup>
          {this.state.spaces.map((item: any) =>
            <ParkingSpace 
              key={item.id} 
              space={item}
              disabled={this.state.loading}
              onReservationChange={(date:Date, reserved:boolean) => this.changeReservation(date, reserved)}
            />
          )}
          </IonItemGroup>
          <IonButton expand="full" onClick={(e) => this.loadSpaces()}>Odśwież</IonButton>
          <ErrorMessage 
            message={this.state.errorMessage} 
            onDidDismiss={() => this.setState({errorMessage: ''})}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default ParkingTab;
