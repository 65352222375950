import React from 'react';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import './SignIn.css';
import { IonContent, IonPage } from '@ionic/react';
import TabHeader from '../components/TabHeader';

const firebaseUiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/parking',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult: any) => {
      return false;
    },
  },
};

const SignIn: React.FC = () => {
  return (
    <IonPage>
      <TabHeader title="Użyj konta pretius.com"/>
      <IonContent fullscreen>
        <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()}/>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
