import React from 'react';
import { IonItem, IonLabel, IonToggle, IonBadge } from '@ionic/react';
import moment from 'moment';

interface Space {
  id: any
  date: Date
  current: boolean
  reserved: boolean
  left: number
  canCancel: boolean
}

interface Props {
  space: Space
  onReservationChange: any
  disabled?: boolean
}

class ParkingSpace extends React.Component<Props> {
  render() {
    return (
      <IonItem color={this.props.space.current ? 'secondary' : ''}>
        <IonLabel>{moment(this.props.space.date, 'YYYY-MM-DD').format('dddd DD.MM.YYYY')}</IonLabel>
        <IonBadge color="light">Wolne: {this.props.space.left > 0 ? this.props.space.left : 'brak'}</IonBadge>
        <IonToggle slot="end" 
          disabled={
            (!this.props.space.reserved && this.props.space.left === 0) ||
            (this.props.space.reserved && !this.props.space.canCancel) ||
            this.props.disabled
          }
          checked={this.props.space.reserved} 
          onIonChange={e => this.props.onReservationChange(this.props.space.date, e.detail.checked)}
        />
      </IonItem>
    );
  }
}

export default ParkingSpace;
